
import * as React from "react";
import { Trad, TradClassName } from "trad-lib";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { VertexAutocomplete } from "adwone-lib/index";
import { Element } from "../Generic/Common.bin";
import { useState } from "react";
import { AdvancedFilters } from "./AdvancedFilters.bin";
import { getIcon } from "adwone-lib/index";
import { TooltipManager } from "../../CustomTooltip";
import { CustomIconButton } from "../Generic/CustomIconButton";
import { AdvertiserHierarchyFilter, Filter } from 'hub-lib/models/orientdb/ref_FilterConfigurations.bin';
import { ClearEmptyValues, clearEmptyValues, clone, compareObjects, duplicate, propertyOf } from "hub-lib/tools.bin";
import { ModeleCreatorBase } from "../ModeleCreator/ModeleCreatorBase";
import { ref_FilterConfigurations } from "hub-lib/models/orientdb/ref_FilterConfigurations.bin";
import { FilterStorage, GetUser, IsMMUser } from "../../../utils/localstorage.bin";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setFilters } from "../../../redux/projectSlice";
import { eGroupCategories, ref_Groups } from "hub-lib/models/ref_Groups.bin";
import { AdvertiserHierarchyComponent, AdvertiserHierarchyComponentProps } from "../AdvertiserHierarchyComponent/AdvertiserHierarchyComponent";
import { StoreFiltersMessages } from "../AdvertiserHierarchyComponent/StoreFilters";
import { DataMapSwitch } from "../../Tools";
import { FiltersChangeTrigger, FiltersChangeTriggerProps } from "./FiltersChangeTrigger";
import { DateRangePickerFilter } from "./DateRangePickerFilter";

export type FiltersComponentProps = {
    onChange?: FiltersChangeTriggerProps['onChange'],
    hide?: {
        AdvancedFilters?: true,
        Hierarchy?: true,
        Date?: true,
        MapFilters?: true,
        Modele?: true,
        AdvertiserHierarchyComponentKeys?: AdvertiserHierarchyComponentProps['hiddenKeys']
    }
}
export function FiltersComponent({ hide, onChange }: FiltersComponentProps) {

    const dispatch = useDispatch();
    const modeleFilters = duplicate(useSelector((root: RootState) => root.project.filtersModele, compareObjects));
    const config = duplicate(useSelector((root: RootState) => root.project.filters, compareObjects));
    config.Start = new Date(config.Start);
    config.End = new Date(config.End);

    const [suffixKey, setSuffixKey] = useState(0);

    const keyConf = `conf_key_${modeleFilters?.["@rid"]}_${suffixKey.toString()}`;

    const saveFilters = (_modeleFilters: ref_FilterConfigurations, event?: {
        type: "contentChanged" | "selectionChanged";
    }) => {
        dispatch(setFilters(_modeleFilters));
    }

    return <>
        {onChange && <FiltersChangeTrigger onChange={onChange} />}

        {/** CLEAR FILTER BUTTON */}
        <div className="view-icons" style={{ display: "flex" }}>
            <CustomIconButton
                onMouseOver={e => TooltipManager.Push({ target: e.target, text: Trad("init_filters") })}
                onClick={() => {
                    const newConfig = new Filter();
                    newConfig.Source = ["ADWONE"];
                    newConfig.Start = config.Start;
                    newConfig.End = config.End;
                    setSuffixKey(suffixKey + 1);
                    saveFilters({ ...modeleFilters, Filters: newConfig });
                }}>{getIcon("filterAltOff")}</CustomIconButton>

            {!hide?.Date && <div className="secondary-container-component">
                <DateRangePickerFilter />
            </div>}
        </div>

        {/** *** SOURCE *** */}
        {!hide?.MapFilters && GetUser().customer?.DisplayMAPData && <DataMapSwitch
            onChange={sources => {
                const newConf = { ...modeleFilters, Filters: config };
                newConf.Filters.Source = sources;
                saveFilters(newConf)
            }} />
        }

        {/** MODELE FILTERS SELECTOR */}
        {!hide?.Modele && <Element>
            <ModeleCreatorBase
                key={`Filters-ModeleCreatorBase-${modeleFilters?.["@rid"]}`}
                objectType={ref_FilterConfigurations}
                onChange={conf => {
                    const confCloned = clone(conf);
                    // TODO temporaire
                    confCloned.Filters = ClearEmptyValues({
                        ...config,
                        ...new AdvertiserHierarchyFilter,
                        ...AdvertiserHierarchyFilter.getFilters(confCloned.Filters)
                    })
                    saveFilters(confCloned);
                }}
                modele={modeleFilters}
                type={ref_Messages.name} />
        </Element> }

        {/** GROUPES */}
        {(!hide?.Hierarchy && !IsMMUser()) &&
            <Element className="adw-row">
                <VertexAutocomplete
                    multiple
                    params={{ [propertyOf<ref_Groups>("Category")]: eGroupCategories.Magasin, Active: true }}
                    type={ref_Groups.name}
                    label={TradClassName(ref_Groups.name)}
                    defaultValue={(options: ref_Groups[]) =>
                        config?.Group?.map(g => options.find(o => o["@rid"] == g)).filter(e => e) ?? []}
                    onChange={async (values: ref_Groups[]) => {
                        config.Group = values?.map(v => v["@rid"]);
                        saveFilters({ ...modeleFilters, Filters: config });
                        setSuffixKey(suffixKey + 1);
                    }} />
            </Element>}

        {!hide?.Hierarchy && <AdvertiserHierarchyComponent
            reduxStore={StoreFiltersMessages}
            store={config}
            hiddenKeys={hide?.AdvertiserHierarchyComponentKeys}
            onChange={store => saveFilters({
                ...modeleFilters,
                Filters: clearEmptyValues({ ...config, ...store })
            })} /> }

        <div className="separator-line adw-row"></div>

        {!hide?.AdvancedFilters &&
            <AdvancedFilters
                key={`AdvancedFilters-${keyConf}`}
                config={config}
                applyConfig={(filters) => saveFilters({ ...modeleFilters, Filters: filters })} />}
    </>
}

export function ModeleSelector() {

    const dispatch = useDispatch();
    const modeleFilters = duplicate(useSelector((root: RootState) => root.project.filtersModele, compareObjects));

    return <ModeleCreatorBase
        key={`ModeleSelector-${modeleFilters.Name}-${modeleFilters["@rid"]}`}
        objectType={ref_FilterConfigurations}
        onChange={conf => {
            const confCloned = clone(conf);
            // TODO temporaire
            confCloned.Filters = ClearEmptyValues({
                ...FilterStorage.getAdvancedFilters(),
                ...new AdvertiserHierarchyFilter,
                ...AdvertiserHierarchyFilter.getFilters(confCloned.Filters)
            })
            dispatch(setFilters(confCloned));
        }}
        modele={modeleFilters}
        type={ref_Messages.name} />
}