import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { lnk_ChangeRate } from "hub-lib/models/lnk_ChangeRate.bin";
import { ref_Campaigns } from "hub-lib/models/ref_Campaigns.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Brands } from "hub-lib/models/orientdb/ref_Brands.bin";
import { ref_Products } from "hub-lib/models/orientdb/ref_Products.bin";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
import { ref_TableConfigurations } from "hub-lib/models/orientdb/ref_TableConfigurations.bin";
import { db } from "validation-lib/db.bin";
import { lnk_HasCurrency } from "hub-lib/models/orientdb/lnk_HasCurrency.bin";
import { ref_Estimates } from "hub-lib/models/ref_Estimates.bin";
import { eKPIType, lnk_HasKPIExtended } from "hub-lib/models/KPIsManager.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { GetCurrentLocale, Trad, TradProp, TradValue } from "trad-lib";

import moment from 'moment';
import { ref_Persons } from "hub-lib/models/orientdb/ref_Persons.bin";
import { UserExtended } from "hub-lib/models/UserExtended.bin";
import { Log } from "hub-lib/models/Log.bin";
import { ref_Customers } from "hub-lib/models/orientdb/ref_Customers.bin";
import { ref_Media } from "hub-lib/models/orientdb/ref_Media.bin";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { ref_AdvertisingCompanies } from "hub-lib/models/orientdb/ref_AdvertisingCompanies.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { SupportExtended } from "hub-lib/models/SupportExtended.bin";
import { lnk_AdvertisingCompanySupport } from "hub-lib/models/orientdb/lnk_AdvertisingCompanySupport.bin";
import { lnk_HasBroadcastArea } from "hub-lib/models/orientdb/lnk_HasBroadcastArea.bin";
import { FormatDate, getWeekNumber } from "tools-lib";
import { ref_Agreements } from "hub-lib/models/ref_Agreements.bin";
import { ref_CustomersExtended } from "../hub-lib/models/custom/ref_CustomersExtended.bin";
import { ref_Imports } from "hub-lib/models/ref_Imports.bin";
import { User } from "hub-lib/models/orientdb/User.bin";
import { lnk_HasPropertyType } from "hub-lib/models/orientdb/lnk_HasPropertyType.bin";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { lnk_HasKPI } from "hub-lib/models/orientdb/lnk_HasKPI.bin";
import { ref_Contacts } from "hub-lib/models/orientdb/ref_Contacts.bin";
import { ref_PropertyType } from "hub-lib/models/orientdb/ref_PropertyType.bin";

export function HTMLToString(htmlStr: string) {
    return htmlStr?.replace?.(/<\/?[^>]+>/gi, '')?.replace(/&lt;/g, '<')?.replace?.(/&gt;/g, '>') ?? "";
}

require('moment/locale/fr.js');

export function getDecimalSeparator() {
    const numberWithDecimal = 1.1;
    const localeString = numberWithDecimal.toLocaleString(GetCurrentLocale());
    const decimalSeparator = localeString.charAt(1); // Le séparateur est entre les deux chiffres
    return decimalSeparator;
}

let dicoFormat = {
    [ref_Contacts.name]: (ctct: ref_Contacts) => ctct.Email,
    [lnk_HasKPI.name]: (lnk: lnk_HasKPIExtended) => Trad(lnk.NameLnk ?? lnk.Name),
    [lnk_HasPropertyType.name]: (lnk) => TradProp(`ModelProperties.${lnk.Name}` as any, ref_Messages),
    [lnk_HasCurrency.name]: (c: ref_Currencies) => `${c.Name} (${c.Code})`,
    [ref_Currencies.name]: (c: ref_Currencies) => `${c.Name} (${c.Code})`,
    [ref_Advertisers.name]: (a: ref_Advertisers) => `${a.Name}`,
    [User.name]: (u: UserExtended) => u.person ? Format(u.person) : `${u.name}`,
    [UserExtended.name]: (u: UserExtended) => Format(u.person),
    [ref_Persons.name]: (a: ref_Persons) => `${a.FirstName} ${a.LastName}`,
}

export const propsNeededForFormat = {
    [ref_Contacts.name]: ["Email"],
    [lnk_HasKPI.name]: ["NameLnk", "Name"],
    [lnk_HasCurrency.name]: ["Name", "Code"],
    [ref_Currencies.name]: ["Name", "Code"],
    [ref_Advertisers.name]: ["Name"],
    [User.name]: ["person", "name"],
    [UserExtended.name]: ["person"],
    [ref_Persons.name]: ["FirstName", "LastName"],
}

let dicoOrder: any = {};

SetOrder<lnk_ChangeRate>(lnk_ChangeRate, [
    "Company",
    "Start",
    "End",
    "in",
    "out",
    "Rate",
    "Created_by",
    "Created_at",
    "Updated_by",
    "Updated_at",
]);
SetOrder<UserExtended>(UserExtended, [
    "person",
    "company",
    "Department",
    "job",
    "mail",
    "registrationDate",
    "qualification",
    "profile",
    "name",
    "lastConnexion",
    "isOnline",
    "sessionsTime",
    "lastAccess"
]);

SetOrder(ref_Campaigns, [
    "Name",
    "Start",
    "End",
    "Source",
    "Group",
    "AdvertiserGroup",
    "Advertiser",
    "Brand",
    "Product",
    "Departments",
    "Budget",
    <any>"KPIs.MessagesCount",
    <any>"KPIs.NetCO",
    "EstimateNumber",
    "Currency",
    "Created_by"
]);

SetOrder<ref_Customers>(ref_Customers, [
    "Company",
    "Country",
    "Licences",
    "Type",
    "Start",
    "End"
]);

SetOrder<ref_Estimates>(ref_Estimates, [
    "Campaign",
    "Status",
    "Code",
    "Lib",
    "Type",
    "Start",
    "End",
    "Created_at",
    "AdvertiserGroup",
    "Advertiser",
    "Brand",
    "Product",
    "Media",
    "MessagesCount",
    "Department",
    "Agency"
]);

SetOrder<ref_Media>(ref_Media, [
    "Name",
    "Start",
    "End"
]);
SetOrder<ref_AdvertisingCompanies>(ref_AdvertisingCompanies, [
    "Name",
    "Start",
    "End"
]);

SetOrder<ref_Currencies>(ref_Currencies, [
    "Label",
    "Name",
    "Code",
    "Start",
    "End"
]);

SetOrder<ref_BroadcastAreas>(ref_BroadcastAreas, [
    "Name",
    "Start",
    "End"
]);
SetOrder<ref_Supports>(ref_Supports, [
    "Name",
    "Medias",
    "Start",
    "End",
    "Periodicity"
]);
SetOrder<SupportExtended>(SupportExtended, [
    "Name",
    "Medias",
    "Start",
    "End"
]);
SetOrder<ref_Property>(ref_Property, [
    "Name",
    "Medias",
    "Start",
    "End"
]);
SetOrder<lnk_AdvertisingCompanySupport>(lnk_AdvertisingCompanySupport, [
    "in",
    "DefaultBroadcastArea",
    "Start",
    "End",
    "Default"
]);
SetOrder<lnk_HasBroadcastArea>(lnk_HasBroadcastArea, [
    "out",
    "Start",
    "End",
    "Default"
]);
SetOrder<lnk_HasCurrency>(lnk_HasCurrency, [
    "out",
    "Start",
    "End",
    "Default"
]);
SetOrder<ref_Imports>(ref_Imports, [
    "Report",
    "Date",
    "SourceType",
    "Customer",
]);
SetOrder<ref_Agreements>(ref_Agreements, [
    "Name",
    "Group",
    "AdvertiserGroup",
    "Advertisers",
    "Brands",
    "Products",
    "Support",
    "BroadcastAreas",
    "Formats",
    "Placements",
    "Start",
    "End",
    "Discounts"
]);

export function Format(data: any, defaultValue?: string, className?: string): string {

    if (!data) return "";

    let formater = dicoFormat[className ?? data?.["@class"]];
    if (formater)
        return formater(data);

    let name = data?.Name;
    if (name) {
        let trad = TradValue(className ?? data?.["@class"], "Name", name);
        return trad ?? name;
    }
    if (defaultValue)
        return defaultValue;
    return data?.toString();
}


let dicoCell: any = {
    [ePropType.Date]: (val: Date) => {
        let res: any = val;
        if (res) {
            try {
                //console.log(``)

                let d = new Date(val);
                if (isNaN(d.getTime()))
                    return val;

                if (typeof res === "string" && res.split("/")?.length === 3) {
                    let sp = res.split("/");
                    d = new Date(Number(sp[2]), Number(sp[1]) - 1, Number(sp[0]))
                }

                //res = val && moment(d).locale(GetCurrentLocale()).format('L')
                let nd = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
                let nm = (d.getMonth() + 1) < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
                let ny = d.getFullYear();

                switch (GetCurrentLocale()) {
                    case "fr-FR":
                        res = `${nd}/${nm}/${ny}`
                        break;

                    case "en-US":
                        res = `${nm}/${nd}/${ny}`
                        break;

                    case "en-GB":
                        res = `${nd}/${nm}/${ny}`
                        break;

                    default:
                        res = val && moment(d).locale(GetCurrentLocale()).format('L')
                        break;
                }

            } catch (error) {
                console.error(`bad date format: ${val}`);
            }
            return res;
        } else { return '' }


    },
    [ePropType.Datetime]: (val: Date) => {
        if (!val) return null;

        val = new Date(val);
        if (isNaN(val.getTime()))
            return val;

        return FormatDate(val, true)
    },
    [ePropType.Double]: (value: number) => value?.toLocaleString(GetCurrentLocale(), { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    [ePropType.Integer]: (value: number) => value?.toLocaleString(GetCurrentLocale(), { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
    [ePropType.Decimal]: (value: number) => value?.toLocaleString(GetCurrentLocale(), { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
    [ePropType.Embedded]: (val: any) => Format(val)
}

export const GetCellClassName = (type: ePropType) => {
    const dico: any = {
        [ePropType.Double]: "number-cell-data",
        [ePropType.Integer]: "number-cell-data",
        [ePropType.Decimal]: "number-cell-data",
        [eKPIType.Price]: "number-cell-data",
        [eKPIType.PriceReturned]: "number-cell-data",
        [eKPIType.PriceBound]: "number-cell-data"
    }
    return dico[type] ?? "";
}

export const GetCellFieldClassName = (field: string, documentType: string) => {
    const dico: any = {
        [ref_Messages.name]: {

        }
    }
    return dico[documentType]?.[field] ?? "";
}

export const GetNumericFormat = (type: ePropType | eKPIType) => {
    const dico: any = {
        [ePropType.Double]: "n2",
        [ePropType.Integer]: "n0",
        [ePropType.Decimal]: "n8",
        [eKPIType.Price]: "n2",
        [eKPIType.PriceReturned]: "n2",
        [eKPIType.PriceBound]: "n2"
    }
    return dico[type] ?? "n2";
}

export const GetNumericStep = (type: ePropType | eKPIType) => {
    const dico: any = {
        [ePropType.Double]: 0.01,
        [ePropType.Integer]: 1,
        [ePropType.Decimal]: 0.001,
        [eKPIType.Price]: 0.01,
        [eKPIType.PriceReturned]: 0.01,
        [eKPIType.PriceBound]: 0.01
    }
    return dico[type] ?? 0.01;
}

const dicoPropName: any = {
    Status: (val: string) => Trad(val),
    DiscountMode: (val: string) => Trad(val),
    State: (val: string) => Trad(val)
}

export function GetPropTemplate(propName: string) {
    return dicoPropName[propName];
}

const dicoPropertyTypeTag: any = {
    TextEditor: (val: string) => HTMLToString(val),
}

export function GetPropertyTypeTemplate(propertyType: ref_PropertyType) {
    return propertyType?.Tags?.map(x => dicoPropertyTypeTag[x])?.filter(x => x)?.[0] ?? null;
}

export let GetCellTemplate = (type: ePropType, propName?: string) => {

    if (propName) {
        let temp = dicoPropName[propName];
        if (temp)
            return temp;
    }

    return dicoCell[type] ?? ((val: any) => {

        if (val && typeof val === "object")
            return JSON.stringify(val);

        return (val ?? "").toString?.() ?? val;
    });
}

export let GetKPITemplate = (type: eKPIType) => {
    let dico: { [prop in eKPIType]: (v: any) => any } = {
        [eKPIType.Number]: (value: number) => GetCellTemplate(ePropType.Integer)(value),
        [eKPIType.Decimal]: (value: number) => GetCellTemplate(ePropType.Double)(value),
        [eKPIType.Rate]: (value: number) => GetCellTemplate(ePropType.Double)(value),
        [eKPIType.Price]: (value: number) => GetCellTemplate(ePropType.Double)(value),
        [eKPIType.PriceReturned]: (value: number) => GetCellTemplate(ePropType.Double)(value),
        [eKPIType.PriceBound]: (value: number) => GetCellTemplate(ePropType.Double)(value),
        [eKPIType.Percent]: (value: any) => (value === undefined || (value as string) === "") ? "" : value?.toLocaleString(GetCurrentLocale(), { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " %",
        [eKPIType.FullDecimal]: (value: any) => (value === undefined || (value as string) === "") ? "" : value?.toLocaleString(GetCurrentLocale(), { minimumFractionDigits: 6, maximumFractionDigits: 6 }),
        [eKPIType.Date]: (value: number) => GetCellTemplate(ePropType.Date)(value),
        [eKPIType.DateTime]: (value: number) => GetCellTemplate(ePropType.Datetime)(value),
        [eKPIType.Rid]: (value: number) => GetCellTemplate(ePropType.String)(value),
        [eKPIType.String]: (value: number) => GetCellTemplate(ePropType.String)(value),
    }
    return dico[type] ?? ((val: any) => val);
}


export function GetOrder<TType>(prototype: new () => TType): (keyof TType)[] {
    return dicoOrder[prototype.name] ?? [];
}

export function SetOrder<TType>(prototype: new () => TType, props: (keyof TType)[]) {
    dicoOrder[prototype.name] = props;
}

let dicoSort: any = {};

export interface SortDescriptor<TType> {
    /**
     * The field that is sorted.
     */
    field: (keyof TType);
    /**
     * The sort direction. If no direction is set, the descriptor will be skipped during processing.
     *
     * The available values are:
     * - `asc`
     * - `desc`
     */
    dir?: 'asc' | 'desc';

    cmp?: (a, b) => any;
}

SetSort<ref_Campaigns>(ref_Campaigns, [{
    field: "Start",
    dir: "desc"
}])

SetSort<Log>(Log, [{
    field: "Date",
    dir: "desc",
    cmp: function (a: any, b: any) { return new Date(b.Date).getTime() - new Date(a.Date).getTime() }
}])
SetSort<ref_Imports>(ref_Imports, [{
    field: "Date",
    dir: "desc",
    cmp: function (a: any, b: any) { return new Date(b.Date).getTime() - new Date(a.Date).getTime() }
}])
let nameSort: any = {
    field: "Name",
    dir: "asc",
    cmp: function (a: any, b: any) { return a.Name?.localeCompare(b.Name) }
};

SetSort<ref_TableConfigurations>(ref_TableConfigurations, [nameSort, {
    field: "Default",
    dir: "desc",
    cmp: function (a: any, b: any) {
        if (a.Default && !b.Default) return -1;
        if (!a.Default && b.Default) return 1;
        return 0;
    }
}])

SetSort<lnk_ChangeRate>(lnk_ChangeRate, [{
    field: "Company",
    dir: "asc"
},
{
    field: "Start",
    dir: "desc"
},
{
    field: "in",
    dir: "asc"
}]);

SetSort<ref_CustomersExtended>(ref_CustomersExtended, [{
    field: "Company",
    dir: "asc"
}]);

SetSort<UserExtended>(UserExtended, [{
    field: "person",
    dir: "asc",
    cmp: function (a: any, b: any) {
        if (a.person && !b.person) return 1;
        if (!a.person && b.person) return -1;
        if (!a.person && !b.person) return 0;
        return a.person.LastName.localeCompare(b.person.LastName);
    }
}]);

SetSort<ref_AdvertiserGroups>(ref_AdvertiserGroups, [nameSort]);
SetSort<lnk_HasBroadcastArea>(lnk_HasBroadcastArea, [nameSort]);
SetSort<ref_Advertisers>(ref_Advertisers, [nameSort]);
SetSort<ref_Brands>(ref_Brands, [nameSort]);
SetSort<ref_Products>(ref_Products, [nameSort]);
SetSort<ref_Supports>(ref_Supports, [nameSort]);
SetSort<SupportExtended>(SupportExtended, [nameSort]);
SetSort<ref_Contacts>(ref_Contacts, [{
    field: "Email",
    dir: "asc",
    cmp: function (a: any, b: any) { return a.Email?.localeCompare(b.Email) }
}])

export function SetSort<TType>(prototype: new () => TType, sorts: SortDescriptor<TType>[]) {
    dicoSort[prototype.name] = sorts;
}

export function GetSort<TType>(prototype: new () => TType) {
    return dicoSort[prototype.name];
}

export function Sort<TType>(name: string, data: TType[]): TType[] {

    if (!data || data?.length == 1)
        return data;

    let sorter: SortDescriptor<TType>[] = dicoSort[name];
    sorter?.forEach(s => {
        if (s.cmp) data = [...data].sort(s.cmp)
        else {
            data = [...data].sort(function (a: any, b: any) {
                return a[s.field]?.toString?.()?.localeCompare(b[s.field]?.toString?.())
            });
            if (s.dir == "desc")
                data.reverse();
        }
    });

    if (!sorter) {
        let nameExists = db[name]?.mandatory?.some(p => p == "Name") || db[name]?.optional?.some(p => p == "Name");
        if (nameExists) {
            data = [...data].sort(nameSort.cmp);
        }
    }

    return data;
}

export const FormatMonth = (d: Date) => Trad(`month_${d.getMonth()}`);
export const FormatDay = (d: Date) => Trad(`day_${d.getDay()}`)[0] + (d.getDate() < 10 ? `0${d.getDate()}` : d.getDate());
export const FormatWeek = (d: Date) => `${Trad('week_short')} ${getWeekNumber(d)}`;
export const FormatWeekShort = (d: Date) => `${Trad('week_very_short')}${getWeekNumber(d)}`;