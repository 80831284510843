import React from "react";
import { VertexAutocomplete } from "adwone-lib";
import { ref_Campaigns } from "hub-lib/models/ref_Campaigns.bin";
import { duplicate, extractSub } from "hub-lib/tools.bin";
import { useSelector } from "react-redux";
import { TradClassName } from "trad-lib";
import { StoreProps } from "./AdvertiserHierarchyComponent";
import { RootStateFilters } from "./StoreFilters";

export function CampaignFilter({ store, onChange }: StoreProps) {
    const storeSaved = duplicate(useSelector((root: RootStateFilters) => root.advertiserStorage?.store) ?? {});
    const onChanged = (values: ref_Campaigns[]) => {
        store.Campaign = values.map(v => v["@rid"]);
        onChange?.(store);
    };

    return <VertexAutocomplete
        type={ref_Campaigns.name}
        label={TradClassName(ref_Campaigns.name)}
        multiple
        refreshOnPropChanged
        params={{
            ...extractSub(storeSaved, ["AdvertiserGroup"]),
            ...extractSub(store, ["Source", "Start", "End"]),
            Active: true
        }}
        defaultValue={(values: ref_Campaigns[]) => {
            const selectedValues = values?.filter(v => store?.Campaign?.includes(v["@rid"])) ?? [];
            if (selectedValues.length != (store?.Campaign?.length ?? 0))
                onChanged(selectedValues);
            return selectedValues;
        }}
        onChange={onChanged}
    />
}